import { thumbnailFilter } from 'components/cards/ProjectCard/ProjectCard.styles';
import { Button } from 'components/common/buttons/Button';
import { Label } from 'components/typography';
import Image from 'next/image';
import styled, { keyframes } from 'styled-components';

export const SessionContainer = styled.div`
  width: 192px;
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
  display: flex;
  margin-right: 16px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  transition: all 0.25s ease-out;
`;

export const Thumbnail = styled(Image)`
  overflow: hidden;
  height: 256px;
  width: auto;
  object-fit: cover;
  ${thumbnailFilter}
  border-radius: ${({ theme }) => theme.borderRadius.large};
  transition: all 0.25s ease-out;

  &:hover {
    transition: all 0.4s ease-out;
    transform: scale(1.1);
  }
`;

export const Online = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.appDarkGrayLighter}40;
  padding: 0 18px 0 12px;
  margin: 8px 8px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-radius: 20px;
  backdrop-filter: blur(8px);
  height: 40px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
`;

export const Title = styled(Label)`
  margin-top: 0.5rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.primaryColor.white};
  &:hover {
    cursor: pointer;
  }
`;

export const PlayContainer = styled.div`
  display: flex;
  padding-bottom: 8px;
  justify-content: center;
`;

export const ImageBackground = styled(Image)<{ backgroundUrl: string }>`
  padding: 8px;
  overflow: hidden;
  height: 0px;
  &::before {
    border-radius: 8px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url(${({ backgroundUrl }) => backgroundUrl});
    ${thumbnailFilter}
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const pulse = keyframes`
	0% {
		transform: scale(0.90);
		box-shadow: 0 0 0 0 #09B584AA;
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 2px #09B58400;
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #09B58400;
	}
`;

const ringPulse = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  /* 70% {
    opacity: 0.0;
		transform: scale(1.5);
  } */
  100% {
    opacity: 0.0;
    transform: scale(3);
  }
`;

export const Container = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const OnlineDot = styled.div<{ color?: string }>`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({ theme, color }) => color || theme.primaryColor.green};
  position: absolute;
  /* animation: ${pulse} 2s infinite; */
`;

export const OnlineRing = styled.div<{ size: number; delay: number; color?: string }>`
  position: absolute;
  background-color: transparent;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  border: solid 1px ${({ theme, color }) => color || theme.primaryColor.green};
  animation: ${ringPulse} 2s infinite;
  animation-delay: ${({ delay }) => delay}s;
`;

export const Wrapper = styled.div`
  padding-bottom: 8px;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.primaryColor.white};
`;

export const SmallButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  padding: 4px 16px;
  border-radius: 12px;
  height: 24px;
  min-height: unset;
  color: ${({ theme }) => theme.primaryColor.white};
  pointer-events: auto;
`;

export const TitleContainer = styled.div`
  max-width: 192px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
